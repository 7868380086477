import dayjs from 'dayjs';
import { memo, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useInterval } from 'react-use';

export default memo(function DownloadClassifiedProjects() {
    const handleDownload = async () => {
        const toastId = toast.loading('Exportando projetos classificados...');

        const response = await fetch(import.meta.env.VITE_API_URL + 'classified');
            const data = await response?.json();

            if(!data?.success) return toast.error('Falha ao baixar os projetos classificados.');

            const classifiedProjects = data?.projects;

            // Keep only the title, description, classification and skills
            const projects = classifiedProjects.map(({ title, description, classification, skills }) => {
                const project = {
                    title,
                    description,
                    classification,
                    skills,
                }

                // Rename the classification to label
                project.label = project.classification;
                delete project.classification;

                // The skills are an array of objects, we only need the text
                project.skills = project.skills.map(skill => skill.text);

                return project;
            });

            const fileName = `classified-projects-${dayjs().format('YYYY-MM-DD')}.json`;
            const json = JSON.stringify(projects, null, 4);

            const blob = new Blob([json], { type: 'application/json' });
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(url);

            // toast.success('Arquivo exportado com sucesso!');
            toast.success('Arquivo exportado com sucesso!', { id: toastId });
    };

    return (
        <button className="download-classified-projects" onClick={handleDownload}>
            <Icon name="mdi:download" />
        </button>
    );
});
